import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../Layout';
import DataTable from '../../components/DataTable'
import { Link } from 'react-router-dom';
import API from '../../utils/API';
import { NotificationManager } from 'react-notifications';
import { Modal, Button, Form } from "react-bootstrap";
import { confirmAlert } from 'react-confirm-alert'; 
import { fetchDepotList, fetchLastDepotList } from '../../store/actions/depotActions';
import { dateFormatTexte } from '../../utils/function';

const DepotListScreen = () => {
    const dispatch = useDispatch();

    const {depots} = useSelector((state) => state.depotsList);
    const authInfos = useSelector((state) => state.auth)

    const [newFolderName, setnewFolderName] = useState('')
    const [newDescription, setNewDescription] = useState()
    const [showModalAddDepot, setShowModalAddDepot] = useState(false)
    const [showModalRenameDepot, setShowModalRenameDepot] = useState(false)
    const [idFolderToEdit, setidFolderToEdit] = useState('')
    const [OldFolderName, setOldFolderName] = useState('')

    const colsArray = [
        {
            label: 'Nom du dépôt',
            field: 'nom_dossier',
        },
        {
            label: 'Description',
            field: 'description',
        },
        {
            label: 'Date de la création',
            field: 'date_ajout',
        },
        {
            label: 'Date de la dernière modification',
            field: 'date_update',
        },
        {
            label: 'Actions',
            field: 'actions',
            width: 150,
        }
    ]

    const getRowsArray = () => {
        let rowsArray = [];
        if(depots){
            depots.map((depot) => {
                const row = {
                    nom_dossier: depot.nom,
                    description : depot.description,
                    date_ajout : dateFormatTexte(depot.date_ajout),
                    date_update : dateFormatTexte(depot.date_update),
                    actions: getLayoutActions(depot) 
                }
                rowsArray.push(row)
            });
        }
        return rowsArray;
    }

    const getLayoutActions = (depot) => (
        <div className="dropdown">
            <a href="#" className="btn btn-floating" data-toggle="dropdown">
                <i className="ti-more-alt"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
                <Link to="#" className="dropdown-item" onClick={(e) => onEditHandler(e, depot.id_dossier, depot.nom, depot.description)}><i className="fas fa-highlighter"></i> Edition</Link>
                <a href="#" className="dropdown-item" onClick={(e) => deleteDepotHandler(e, depot.id_dossier, depot.slug)}><i className="fas fa-edit"></i> Supprimer</a>
            </div>
        </div>
    )

    const addDepotHandler = async () => {
        if(newFolderName.length === 0){
            NotificationManager.error(`Le dépôt ne doit pas être vide`, 'Erreur', 5000);
            return;
        }
        const data = { 
            nom:newFolderName,
            id_parent:0,
            is_archive:0,	
            id_user_creation: authInfos.userInfo.id_user,
            actif:1,
            type:1,
            description : newDescription
        }
        try {
            const response = await API.post(`/dossiers?type=depot`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Le dossier ${newFolderName} a été crée`, 'Succés', 5000);
                dispatch(fetchDepotList());
                dispatch(fetchLastDepotList(10, 'date_update'))
            }
            setnewFolderName('')
            setNewDescription('')
            setShowModalAddDepot(false)
        } catch (err) {
            console.error(err.message)
            setnewFolderName('')
            setNewDescription('')
            setShowModalAddDepot(false)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        }
    }

    const onEditHandler = (e, idFolderToEdit, OldFolderName, OldDescription) => {
        e.preventDefault()
        setidFolderToEdit(idFolderToEdit)
        setOldFolderName(OldFolderName)
        setnewFolderName(OldFolderName)
        setNewDescription(OldDescription)
        setShowModalRenameDepot(true)

    }

    const renameDepotHandler = async () => {
        if(newFolderName.length === 0){
            NotificationManager.error(`Le dépôt ne doit pas être vide`, 'Erreur', 5000);
            return;
        }
        let action = 'updatedb';
        if(newFolderName !== OldFolderName){
            action = "rename";
        }
        const data = { 
            id: idFolderToEdit,
            oldName: OldFolderName,
            nom :newFolderName,
            description : newDescription
        }
        try {
            const response = await API.put(`/dossiers/actions?action=${action}`, data)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Le dépôt a été modifier`, 'Succés', 5000);
                dispatch(fetchDepotList());
            }
            setnewFolderName('')
            setShowModalRenameDepot(false)
        } catch (err) {
            console.error(err.message)
            setnewFolderName('')
            setShowModalRenameDepot(false)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        }
    }

    const deleteDepotHandler = (e, id_dossier, OldFolderName) => {
        e.preventDefault()
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer ce dépôt?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => deleteDepotConfirm(id_dossier, OldFolderName)
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }

    const deleteDepotConfirm = async (id_dossier, OldFolderName) => {
        try {
            const response = await API.put(`/depots/delete/${id_dossier}?source=${OldFolderName}`)
            const { data:{success }} = response;
            if (success) {
                NotificationManager.success(`Le dossier ${OldFolderName} a été supprimer`, 'Succés', 5000);
                dispatch(fetchDepotList());
            }
        } catch (err) {
            console.error(err.message)
            const errorMsg = err.response && err.response.data ? err.response.data.error : err.message;
            NotificationManager.error("Une erreur est survenue : "+errorMsg, 'Erreur', 5000);
        }
    }
    return (
        <Layout>
            <div className="page-header d-flex justify-content-between">
                <div className="content-title" style={{ width: "100%" }}>
                    <h4>Mes dépôts</h4>
                </div>
                <div className="btn" style={{margin: '30px 0', width:'20%'}}>
                    <Link to="#" onClick={() => setShowModalAddDepot(true)}> 
                        <i className="fas fa-plus-circle"></i> 
                        <span className="px-2 d-none d-md-block">Ajouter un dépôt</span>
                    </Link>
                </div>
            </div>
            <DataTable
                rows = {getRowsArray()}
                columns = {colsArray}
                pagesRange = {[20, 30, 50, 100]}
                widthPagination = {false}
            />
            {showModalAddDepot && 
                <Modal show={showModalAddDepot} onHide={() =>setShowModalAddDepot(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Ajouter un dépôt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId='Réference'>
                            <Form.Label>Nom du dépôt</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Entrer un nom'
                                value={newFolderName}
                                onChange={(e) => setnewFolderName(e.target.value)}
                                required
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={() =>setShowModalAddDepot(false)}>
                        Annuler
                    </Button>
                    <Button variant="success" onClick={addDepotHandler}>
                        Valider
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
            {showModalRenameDepot && 
                <Modal show={showModalRenameDepot} onHide={() =>setShowModalRenameDepot(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Édition dépôt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId='NameFolder'>
                            <Form.Label>Nom du dépôt</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Entrer un nom'
                                value={newFolderName}
                                onChange={(e) => setnewFolderName(e.target.value)}
                                required
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} value={newDescription} onChange={(e) => setNewDescription(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={() =>setShowModalRenameDepot(false)}>
                        Annuler
                    </Button>
                    <Button variant="success" onClick={renameDepotHandler}>
                        Valider
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
        </Layout>
    )
}


export default DepotListScreen;