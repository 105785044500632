export const DEPOTS_LIST_REQUEST = 'DEPOTS_LIST_REQUEST';
export const DEPOTS_LIST_SUCCESS = 'DEPOTS_LIST_SUCCESS';
export const DEPOTS_LIST_FAIL = 'DEPOTS_LIST_FAIL';
export const DEPOTS_LIST_RESET = 'DEPOTS_LIST_RESET';
export const UPDATE_CURRENT_DEPOT = 'UPDATE_CURRENT_DEPOT';

export const DEPOTS_CONTENT_REQUEST = 'DEPOTS_CONTENT_REQUEST';
export const DEPOTS_CONTENT_SUCCESS = 'DEPOTS_CONTENT_SUCCESS';
export const DEPOTS_CONTENT_FAIL = 'DEPOTS_CONTENT_FAIL';

export const LAST_DEPOTS_LIST_FAIL = 'LAST_DEPOTS_LIST_FAIL';
export const LAST_DEPOTS_LIST_SUCCESS = 'LAST_DEPOTS_LIST_SUCCESS';
export const LAST_DEPOTS_LIST_REQUEST = 'LAST_DEPOTS_LIST_REQUEST';