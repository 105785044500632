import { GET_LOG_ACTION_USERS,LOG_USERS_ACTION_LIST_FAIL,
         USER_ACTION_DISPATCH_FAIL,USER_ACTION_DISPATCH } from './types'
import API from '../../utils/API';
import axios from "axios";

export const getLogActionUser = () => async(dispatch) => {
    try {
        const { data } = await API.get(`/logs_actions`)

        dispatch({
            type: GET_LOG_ACTION_USERS,
            payload: data.results,
        })
    } catch (error) {
        dispatch({
            type: LOG_USERS_ACTION_LIST_FAIL,
            payload: error.message,
        })
    }
}

export const saveLogActionUser = (id_dossier,type_action,destination = null,nom_change = null) => async(dispatch) => {
    try {
         //const ip_adresse = await axios.get('https://geolocation-db.com/json/');
         //ip_adresse = ip_adresse.data.IPv4;
        let ip_adresse = '127.0.0.1';

        try {
            const ipify = await axios.get('https://api.ipify.org/?format=json');
            ip_adresse = ipify.data.ip || ip_adresse;
        } catch (error) {
            console.error('Failed to get IP address, using default', error);
        }

        //  const api_get_ip = await API.get("/users/ip-adresse");
        //  if (api_get_ip.status == 200) {
        //     ip_adresse = api_get_ip.data.ip;
        //  }
 
        const { data } = await API.post(`/logs_actions/create`, {id_dossier:id_dossier,type_action:type_action,destination:destination,nom_change:nom_change,ipadresse:ip_adresse})

        dispatch({
            type: USER_ACTION_DISPATCH,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: USER_ACTION_DISPATCH_FAIL,
            payload: error.message,
        })
    }
}




