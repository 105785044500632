import { GET_LOG_ACTION_USERS,LOG_USERS_ACTION_LIST_FAIL,
         USER_ACTION_DISPATCH_FAIL,USER_ACTION_DISPATCH  } from '../actions/types'

const initialState = {
    listeAction: [],
    error: null,
};

export const logsActionUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOG_ACTION_USERS:
            return {
                listeAction: action.payload,
              }; 
        case USER_ACTION_DISPATCH:
            return state;
        case LOG_USERS_ACTION_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                }; 
        case USER_ACTION_DISPATCH_FAIL:
            return {
                ...state,
                error: action.payload,
                };           
        default:
            return state
    }
}

