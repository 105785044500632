import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { forgetPassword } from '../store/actions/authAction';
import Loader from '../components/Loader';
import Message from '../components/Message'
import {PASSWORD_RESET_INIT} from '../store/constants/forgot'
import { NotificationManager } from 'react-notifications';

const Forget = ({ location, history}) => {
    const [email, setEmail] = useState('')
    const handleChangeEmail = (e) => setEmail(e.target.value);
    const dispatch = useDispatch();

    const forgetReducer = useSelector((state) => state.forgetReducer);
    const { success, loading, error } = forgetReducer;
    
    useEffect(() => {
        if (success) {
            NotificationManager.success(`Vous recevrez un lien pour réinitialiser votre mot de passe`, 'Succés', 5000);
            dispatch({type:PASSWORD_RESET_INIT})
            history.push("/")
        }
    }, [success])

    const handleOnSubmit = (e) => {
        e.preventDefault();
        // login(user);
        dispatch(forgetPassword(email))
    };
    return (
        <>
            <div className="form-membership" style={{background: 'url(media/image/image1.jpg)'}}>
                <div className="form-wrapper">

                    {/* <!-- logo --> */}
                    <div id="logo">
                        <img src="media/image/logo-dark.png" alt="logo" />
                    </div>
                    {/* <!-- ./ logo --> */}


                    <h5>Ré-initialiser votre mot de passe</h5>
                    <p>Entrer votre email et une demande de réinitialisation de mot de passe vous sera envoyé</p>
                    {/* <!-- form --> */}
                    
                    {error && <Message variant='danger'>{error || "Une erreur est survenue"}</Message> }
                    {loading && <Loader/> }
                    <form>
                        <div className="form-group">
                            
                            <input type="email" className="form-control" placeholder="Email" required autoFocus onChange={handleChangeEmail} />
                            
                        </div>
                        <button className="btn btn-primary btn-block" onClick={handleOnSubmit} disabled={loading}>Réinitiser mot de passe</button>
                        <Link to='/'><button className="btn btn-secondary btn-block my-3" >Connexion</button></Link>
                        
                    </form>
                    {/* <!-- ./ form --> */}

                </div>
            
            </div>
        </>
        )
}
export default Forget;
