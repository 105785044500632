import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {formatDate} from '../../utils/function';
import ExportExcel  from '../excelexport';
import Layout from '../Layout';
import DataTable from '../../components/DataTable'

const HistoriqueConnexion = () => {

    const colsArray = [
        {
            label: 'ID utilisateur',
            field: 'id_user',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'ID utilisateur',
            },
        },
        {
            label: 'Nom & prénom',
            field: 'nom_user',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Nom & prénom',
            },
        },
        {
            label: 'Email',
            field: 'email',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Email',
            },
        },
        {
            label: 'Message',
            field: 'ip_adresse',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Message',
            },
        },
        {
            label: 'Date de connexion',
            field: 'date_connexion',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Date de connexion',
            },
        }
    ];



    const {logsUsers} = useSelector((state) => state.userLogs);

    const ExcelExportData = () => {
        let rowsArray = [];
        logsUsers.results.map((user) => {
            const row = {
                "Id Utilisateur": user.id_user,
                "Nom & Prénom": user.nom_user,
                "Email": user.email,
                "Message": 'Connexion au back-office depuis '+user.ip_adresse,
                "Date de connexion": formatDate(user.date_connexion),
            }
            
            rowsArray.push(row)
            return null;
        });

        return rowsArray;
    }
    const wscols = [ {wch:10}, {wch:20}, {wch:30}, {wch:50},{wch:30}];

    const getRowsArray = () => {
        let rowsArray = [];
        logsUsers.results.map((user) => {
            const row = {
                id_user: user.id_user,
                nom_user: user.nom_user,
                email: user.email,
                ip_adresse: getMessage(user.ip_adresse,user.nom_user),
                date_connexion: formatDate(user.date_connexion),
            }
            
            rowsArray.push(row)
            return null;
        });

        return rowsArray;
    }

    const getMessage = (ip_adresse,user) => (
        <div className="">
             <span>Connexion au back-office depuis</span> <br/>
             <span>{ip_adresse}</span>
        </div>
    )

    return (
        <>
           <ExportExcel excelData={ExcelExportData()} fileName={"historique-connexion"} wscols={wscols} />
           {
                logsUsers && logsUsers.results.length > 0 ?(<DataTable
                    rows = {getRowsArray()}
                    columns = {colsArray}
                    pagesRange = {[20, 30, 50, 100]}
                />):(<DataTable
                    columns = {colsArray}
                    pagesRange = {[20, 30, 50, 100]}
                />)
            }
        </>
    )
}

export default HistoriqueConnexion
