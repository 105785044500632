import React from 'react'
import { Route, Redirect } from 'react-router-dom';

const SecuredRoute = (props) => {
    const {loading, path, isLoggedIn, isAdmin, roles} = props;
    return(
        <>
            {
                isLoggedIn !== null && !loading ?
                    <Route 
                        path={path} 
                        render={ data => 
                            isLoggedIn ?
                                (isAdmin ?
                                    roles === 'admin' ?
                                        <props.component {...data}></props.component> :
                                        <Redirect to={{pathname:'/404'}}></Redirect>
                                    : <props.component {...data}></props.component>) :
                                (<Redirect to={{pathname:'/'}}></Redirect>) 
                        }
                    >
                    </Route>
                : null
            }
        </>
    )
}

export default SecuredRoute
