import { useEffect,useState  } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import DataTable from '../../components/DataTable'
import HistoriqueConnexion from './HistoriqueConnexion';
import { listUsers, deleteUser } from '../../store/actions/userActions';
import { getLogUser } from '../../store/actions/historiquesConnexionAction';
import Layout from '../Layout';
import {getUrlSiteByEnv} from '../../utils/function';
import { USER_DETAILS_RESET } from '../../store/actions/types'

const UserListScreen = () => {

    const [tabs,setTabs] = useState('tab1');
    const colsArray = [
        {
            label: 'Réference',
            field: 'ref',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Réference',
            },
        },
        {
            label: 'Nom & prénom',
            field: 'nom_prenom',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Nom & prénom',
            },
        },
        {
            label: 'Email',
            field: 'email',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Email',
            },
        },
        {
            label: 'Rôle',
            field: 'ref_role',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Rôle',
            },
        },
        {
            label: 'Actif',
            field: 'actif',
            width: 150,
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Actif',
            },
        },
        {
            label: 'Actions',
            field: 'actions',
            width: 150,
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Actions',
            },
        }
    ]
    const dispatch = useDispatch();
    const {users} = useSelector((state) => state.userListReducer);


    const userDelete = useSelector((state) => state.userDelete)
    const { success: successDelete, error: errorDelete } = userDelete

    useEffect(() => {
        dispatch({ type: USER_DETAILS_RESET })
        dispatch(listUsers())
        dispatch(getLogUser())
        if (successDelete) {
            NotificationManager.success('Vos modifications ont été effectués avec succés', 'Succés', 5000);
        }else if (errorDelete) {
            NotificationManager.error(`Une erreur est survenue : ${errorDelete}`, 'Erreur', 5000);
        }
    }, [dispatch, successDelete, errorDelete])

    const getRowsArray = () => {
        let rowsArray = [];
        users.map((user) => {
            const row = {
                ref: user.ref_user,
                nom_prenom: user.nom_prenom,
                email: user.email,
                image: getLayoutImage(user.image, user.nom_prenom),
                ref_role : user.ref_role,
                actif: user.actif === 1 ? 
                            <div className="badge bg-success-bright text-success">Actif</div> :
                            <div className="badge bg-danger-bright text-danger">Non Actif</div>,
                actions: getLayoutActions(user.id_user) 
            }
            rowsArray.push(row)
            return null;
        });
        return rowsArray;
    }
    
    const getLayoutImage = (img, username) => (
        <div className="avatar-group">
            <figure className="avatar avatar-sm" data-toggle="tooltip" title={username}>
                {img ? 
                    <img src={`${getUrlSiteByEnv()}/${process.env.REACT_APP_USER_IMAGE_FOLDER}/${img}`} 
                        className="rounded-circle"  alt="user" /> :
                    <span className="avatar-title rounded-circle"><i className="far fa-user"></i></span>
                }
            </figure>
        </div>
    )

    const getLayoutActions = (id_user) => (
        <div className="dropdown">
            <a href="#" className="btn btn-floating" data-toggle="dropdown">
                <i className="ti-more-alt"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
                <Link to={`/admin/user/${id_user}/edit`} className="dropdown-item"><i className='fas fa-edit'></i> Modifier</Link>
                <Link
                    to="#"
                    className='dropdown-item'
                    onClick={() => deleteUserHandler(id_user)} 
                >
                    <i className='fas fa-trash'></i> Supprimer
                </Link>
            </div>
        </div>
    )
    const deleteUserHandler = (id_user) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer cet utilisateur?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => dispatch(deleteUser(id_user))
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }

    return (
        <Layout>
       
            <div className="page-header d-flex justify-content-between page-user">
                <div className='header-tabs'>
                    <div className={tabs == 'tab1'?'content-title active':'content-title'} onClick={()=> setTabs('tab1')}>
                        <h4>Liste des utilisateurs</h4>
                    </div>
                    <div className={tabs == 'tab2'?'content-title active':'content-title'}  onClick={()=> setTabs('tab2')}>
                        <h4>Historique de connexion </h4>
                    </div>
                </div>
                <div className="btn" style={{margin: '30px 0', width:'20%'}}>
                    <Link to="/admin/user/create"> 
                        <i className="fas fa-plus-circle"></i> 
                        <span className="px-2 d-none d-md-block">Ajouter utilisateur</span>
                    </Link>
                </div>
            </div>
            {
                tabs == 'tab1' && (users && users.length> 0 ?
                    <DataTable
                        rows = {getRowsArray()}
                        columns = {colsArray}
                        pagesRange = {[20, 30, 50, 100]}
                    /> :
                    <DataTable
                        columns = {colsArray}
                        pagesRange = {[20, 30, 50, 100]}
                    />)
            }

            {
               tabs == 'tab2' && <HistoriqueConnexion/>
            }
        </Layout>
    )
}

export default UserListScreen
