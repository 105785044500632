const footer = () => {
    return ( <footer className="content-footer d-print-none">
    <div>© 2020 Prespharm Outre-Mer - Tous droits réservés
        {/* <a href="http://laborasyon.com" target="_blank" rel="noreferrer"></a> */}
    </div>
    {/* <div>
        <nav className="nav">
            a href="https://themeforest.net/licenses/standard" className="nav-link">Licenses</a>
            <a href="#" className="nav-link">Change Log</a>
            <a href="#" className="nav-link">Get Help</a> 
            Développé par Evo Consulting
        </nav>
    </div> */}
</footer>)
}
export default footer