import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Layout from '../Layout';
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import FormContainer from '../../components/FormContainer'
import { NotificationManager } from 'react-notifications';
import { getRoleDetails, updateRole, createRole } from '../../store/actions/roleActions'
import {
    ROLE_UPDATE_RESET, ROLE_CREATION_RESET
} from './../../store/RoleTypes'

const RoleDetailsScreen = ({match, history }) => {
    const dispatch = useDispatch()

    const roleId = match.params.id;

    const [RefRole, setRefRole] = useState('')
    const [NomRole, setNomRole] = useState('')
    const [isActif, setIsActif] = useState('')

    const roleDetails = useSelector((state) => state.roleDetails)
    const { loading, role } = roleDetails

    const roleUpdate = useSelector((state) => state.roleUpdate)
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate} = roleUpdate

    const roleCreate = useSelector((state) => state.roleCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate} = roleCreate

    useEffect(() => {
        
        if (successUpdate || successCreate) {
            NotificationManager.success('Modification effectuées avec succés', 'Succés', 5000);
            successUpdate ? dispatch({ type: ROLE_UPDATE_RESET }) : dispatch({ type: ROLE_CREATION_RESET })
            history.push('/admin/Roles')
        } else {
            if (roleId && !role ) {
                dispatch(getRoleDetails(roleId))
            }else if(role && role.ref_role && role.nom_role){
                setRefRole(role.ref_role)
                setNomRole(role.nom_role)
                setIsActif(role.actif)
                && NotificationManager.success('Vos modifications ont été effectués avec succés', 'Succés', 5000);
            } else {
                history.push('/admin/role/create')
            }
        }
        
    }, [dispatch, history, roleId, role, successUpdate, successCreate])

    const submitHandler = (e) => {
        e.preventDefault()

        const submittedRole = {
            ref_role : RefRole,
            nom_role : NomRole,
            actif : isActif ? isActif : 0,
        }
        if (role && role.id_role) {
            dispatch(updateRole(role.id_role, submittedRole))
        } else {
            dispatch(createRole(submittedRole))
        }
    }

    return (
        <Layout>
            <div className="content-title" style={{width: "100%"}}>
                <h4>{roleId ? `Modifier le role ${roleId}` : 'Ajouter un rôle' } :</h4>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    {(loadingUpdate || loadingCreate) && <Loader />}
                    {(errorUpdate || errorCreate) && <Message variant='danger'>{errorUpdate || errorCreate}</Message>}
                    {loading ? 
                        <Loader /> : 
                            <FormContainer>
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId='actif'>
                                        <Form.Check
                                            type='checkbox'
                                            label='Actif'
                                            checked={isActif}
                                            onChange={(e) => setIsActif(e.target.checked)}
                                        ></Form.Check>
                                    </Form.Group>
                                    <Form.Group controlId='ref_role'>
                                        <Form.Label>Réference</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Entrer une réference'
                                            value={RefRole}
                                            onChange={(e) => setRefRole(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='nom_role'>
                                        <Form.Label>Nom du role</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Entrer le Nom du role'
                                            value={NomRole}
                                            onChange={(e) => setNomRole(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="text-center">
                                        <Button type='submit' variant='primary'>
                                            {roleId ? `Modifier` : 'Ajouter' }
                                        </Button>
                                        <Link to="/admin/Roles">
                                            <Button variant='info' className="m-2">
                                                Revenir à la liste
                                            </Button>
                                        </Link>
                                    </div>
                                </Form>
                                
                            </FormContainer>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default RoleDetailsScreen