import {
    ROLE_LIST_REQUEST, ROLE_LIST_SUCCESS, ROLE_LIST_FAIL
} from '../actions/types'
import {
    ROLE_DETAILS_REQUEST, ROLE_DETAILS_SUCCESS, ROLE_DETAILS_FAIL, ROLE_DETAILS_RESET, 
    ROLE_CREATION_REQUEST, ROLE_CREATION_SUCCESS, ROLE_CREATION_FAIL, ROLE_CREATION_RESET,
    ROLE_UPDATE_REQUEST, ROLE_UPDATE_SUCCESS, ROLE_UPDATE_FAIL, ROLE_UPDATE_RESET,
    ROLE_DELETE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_DELETE_FAIL
} from './../RoleTypes'

export const roleReducer = (state = { roles: null }, action) => {
    switch (action.type) {
        case ROLE_LIST_REQUEST:
            return { loading: true }
        case ROLE_LIST_SUCCESS:
            return { loading: false, roles: action.payload }
        case ROLE_LIST_FAIL:
            return { loading: false, roles: null ,error: action.payload }
        default:
            return state
    }
}

export const roleDetailsReducer = (state = { role: null }, action) => {
    switch (action.type) {
        case ROLE_DETAILS_REQUEST:
            return { ...state, loading: true }
        case ROLE_DETAILS_SUCCESS:
            return { loading: false, role: action.payload }
        case ROLE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case ROLE_DETAILS_RESET:
            return { role: null }
        default:
            return state
    }
}

export const roleCreationReducer = (state = {}, action) => {
    switch (action.type) {
        case ROLE_CREATION_REQUEST:
            return { loading: true }
        case ROLE_CREATION_SUCCESS:
            return { loading: false, success: true }
        case ROLE_CREATION_FAIL:
            return { loading: false, error: action.payload }
        case ROLE_CREATION_RESET:
            return {}
        default:
            return state
        }
}

export const roleUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case ROLE_UPDATE_REQUEST:
            return { loading: true }
        case ROLE_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case ROLE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case ROLE_UPDATE_RESET:
            return {}
        default:
            return state
        }
}

export const roleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ROLE_DELETE_REQUEST:
            return { loading: true }
        case ROLE_DELETE_SUCCESS:
            return { loading: false, success: true }
        case ROLE_DELETE_FAIL:
            return { loading: false, success: false , error: action.payload }
        default:
            return state
    }
}