import { Link } from 'react-router-dom'
import {  useSelector } from 'react-redux'
import { withRouter } from "react-router";

const SidebarNavigation = ({ location }) => {

    const authenticatedUser = useSelector((state) => state.auth)
    const { loading, error, userInfo } = authenticatedUser
    return (
        <>
        { !loading && !error && userInfo &&
            <>
                <div className="navigation">
                    <div className="logo">
                        <Link to="/">
                            <img src="media/image/mini-logo.png" alt="logo" />
                        </Link>
                    </div>
                    <ul>
                        <li>
                            <Link to="/" className={location.pathname === '/home' ? 'active' : ''}>
                                <i className="nav-link-icon ti-pie-chart"></i>
                                <span className="text-center nav-link-label">Tableau de bord</span>
                                {/* <span className="badge badge-danger badge-small">2</span> */}
                            </Link>
                        </li>

                        
                     {/*    
                        <li>
                            <a  href="activities.html">
                                <i className="nav-link-icon ti-pulse"></i>
                                <span className="nav-link-label">Activities</span>
                                <span className="badge badge-warning">New</span>
                            </a>
                        </li> */}
                        {
                           userInfo.ref_role === 'admin' && userInfo.id_user == 16 && 
                            <li>
                                <Link  to="/admin/users" className={/^\/admin\/user/i.test(location.pathname) ? 'active' : ''}>
                                    <i className="nav-link-icon ti-user"></i>
                                    <span className="nav-link-label">Utilisateurs</span>
                                </Link>
                            </li>
                        }
                        {
                            userInfo.ref_role === 'admin' && userInfo.id_user == 16 && 
                            <li>
                                <Link  to="/admin/roles" className={/^\/admin\/role/i.test(location.pathname) ? 'active' : ''}>
                                    <i className="nav-link-icon ti-id-badge"></i>
                                    <span className="nav-link-label">Rôles</span>
                                </Link>
                            </li>
                        }
                        {
                            userInfo.ref_role === 'admin' &&                       
                            <li>
                                <Link  to="/mes_depots" className={location.pathname === '/mes_depots' ? 'active' : ''}>
                                    <i className="nav-link-icon ti-file"></i>
                                    <span className="nav-link-label">Depôts</span>
                                </Link>
                            </li>
                        }
                        {
                            userInfo.ref_role === 'admin' &&                       
                            <li>
                                <Link  to="/logs_action" className={location.pathname === '/logs_action' ? 'active' : ''}>
                                    <i className="nav-link-icon ti-file"></i>
                                    <span className="nav-link-label">Logs</span>
                                </Link>
                            </li>
                        }
                        {/* <li className="flex-grow-1">
                            <a href="alert.html">
                                <i className="nav-link-icon ti-layers"></i>
                                <span className="nav-link-label">Components</span>
                            </a>
                        </li>
                        <li>
                            <a  href="settings.html">
                                <i className="nav-link-icon ti-settings"></i>
                                <span className="nav-link-label">Settings</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </>
        }
        </>
    )
}

export default withRouter(SidebarNavigation)
