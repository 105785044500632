import {
    DEPOTS_LIST_REQUEST, DEPOTS_LIST_SUCCESS, DEPOTS_LIST_FAIL,
    LAST_DEPOTS_LIST_REQUEST, LAST_DEPOTS_LIST_SUCCESS, LAST_DEPOTS_LIST_FAIL,
    DEPOTS_CONTENT_REQUEST,DEPOTS_CONTENT_SUCCESS,DEPOTS_CONTENT_FAIL, UPDATE_CURRENT_DEPOT
} from '../constants/depots'

export const depotReducer = (state = { depots: null, currentDepot : null }, action) => {
    switch (action.type) {
        case DEPOTS_LIST_REQUEST:
            return { ...state, loading: true }
        case DEPOTS_LIST_SUCCESS:
            return { ...state, loading: false, depots: action.payload, error:'' }
        case DEPOTS_LIST_FAIL:
            return { ...state, loading: false, depots: null , error: action.payload }
        case UPDATE_CURRENT_DEPOT:
            return { ...state, currentDepot: action.payload }
        default:
            return state
    }
}

export const depotContentReducer = (state = { depotContent: null, access:null }, action) => {
    switch (action.type) {  
        case DEPOTS_CONTENT_REQUEST:
            return { ...state, loading: true, error:'' }
        case DEPOTS_CONTENT_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                depotContent: action.payload.depotContent, 
                access: action.payload.access, 
                error:'' 
            }
        case DEPOTS_CONTENT_FAIL:
            return { ...state, loading: false, depotContent: null , error: action.payload }
        default:
            return state
    }
}

export const lastDepotReducer = (state = { depots: null }, action) => {
    switch (action.type) {
        case LAST_DEPOTS_LIST_REQUEST:
            return { ...state, loading: true }
        case LAST_DEPOTS_LIST_SUCCESS:
            return { ...state, loading: false, depots: action.payload, error:'' }
        case LAST_DEPOTS_LIST_FAIL:
            return { ...state, loading: false, depots: null , error: action.payload }
        default:
            return state
    }
}