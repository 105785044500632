export const SET_LOADING_AUTH = 'SET_LOADING_AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const GET_AUTH_STATUS_ERROR = 'GET_AUTH_STATUS_ERROR';
export const GET_AUTH_STATUS = 'GET_AUTH_STATUS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';
export const USER_UPDATE_PASSWORD_RESET = 'USER_UPDATE_PASSWORD_RESET';


export const FETCH_ALL_DOSSIER = 'FETCH_ALL_DOSSIER';
export const DOSSIER_ERROR = 'DOSSIER_ERROR';
export const FETCH_ONE_DOSSIER = 'FETCH_ONE_DOSSIER';
export const SET_LOADING_DOSSIER = 'SET_LOADING_DOSSIER';
export const FETCH_INDEX_DOSSIER = 'FETCH_INDEX_DOSSIER';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET';

export const USER_CREATION_REQUEST = 'USER_CREATION_REQUEST';
export const USER_CREATION_SUCCESS = 'USER_CREATION_SUCCESS';
export const USER_CREATION_FAIL = 'USER_CREATION_FAIL';
export const USER_CREATION_RESET = 'USER_CREATION_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const ROLE_LIST_REQUEST = 'ROLE_LIST_REQUEST';
export const ROLE_LIST_SUCCESS = 'ROLE_LIST_SUCCESS';
export const ROLE_LIST_FAIL = 'ROLE_LIST_FAIL';

export const FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST = 'FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST';
export const FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS = 'FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS';
export const FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL = 'FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL';

export const ACCESS_BATCH_ADD_UPDATE_REQUEST = 'ACCESS_BATCH_ADD_UPDATE_REQUEST';
export const ACCESS_BATCH_ADD_UPDATE_SUCCESS = 'ACCESS_BATCH_ADD_UPDATE_SUCCESS';
export const ACCESS_BATCH_ADD_UPDATE_FAIL = 'ACCESS_BATCH_ADD_UPDATE_FAIL';
export const ACCESS_BATCH_ADD_UPDATE_RESET = 'ACCESS_BATCH_ADD_UPDATE_RESET';

export const ACCESS_USER_LIST_REQUEST = 'ACCESS_USER_LIST_REQUEST';
export const ACCESS_USER_LIST_SUCCESS = 'ACCESS_USER_LIST_SUCCESS';
export const ACCESS_USER_LIST_FAIL = 'ACCESS_USER_LIST_FAIL';

export const DOSSIER_LIST_REQUEST = 'DOSSIER_LIST_REQUEST';
export const DOSSIER_LIST_SUCCESS = 'DOSSIER_LIST_SUCCESS';
export const DOSSIER_LIST_FAIL = 'DOSSIER_LIST_FAIL';

export const FILES_TREE_REQUEST = 'FILES_TREE_REQUEST';
export const FILES_TREE_SUCCESS = 'FILES_TREE_SUCCESS';
export const FILES_TREE_FAIL = 'FILES_TREE_FAIL';
export const FILES_TREE_RESET = 'FILES_TREE_RESET';

export const DEPOT_TREE_REQUEST = 'DEPOT_TREE_REQUEST';
export const DEPOT_TREE_SUCCESS = 'DEPOT_TREE_SUCCESS';
export const DEPOT_TREE_FAIL = 'DEPOT_TREE_FAIL';
export const DEPOT_TREE_RESET = 'DEPOT_TREE_RESET';

export const SET_CURRENT_PATHNAME = 'SET_CURRENT_PATHNAME';
export const SET_CURRENT_DEPOT = 'SET_CURRENT_DEPOT';
export const SET_CURRENT_FILEINFOS = 'SET_CURRENT_FILEINFOS';

export const FILE_INFO_REQUEST = 'FILE_INFO_REQUEST';
export const FILE_INFO_SUCCESS = 'FILE_INFO_SUCCESS';
export const FILE_INFO_FAIL = 'FILE_INFO_FAIL';
export const FILE_INFO_RESET = 'FILE_INFO_RESET';

export const PATHNAME_REQUEST = 'PATHNAME_REQUEST';
export const PATHNAME_FAIL = 'PATHNAME_FAIL';

export const STATS_REQUEST = 'STATS_REQUEST';
export const STATS_SUCCESS = 'STATS_SUCCESS';
export const STATS_FAIL = 'STATS_FAIL';

export const GET_LOG_USER = 'GET_LOG_USER';
export const LOG_USER_LIST_FAIL = 'LOG_USER_LIST_FAIL';


export const GET_LOG_ACTION_USERS = 'GET_LOG_ACTION_USERS';
export const USER_ACTION_DISPATCH = 'USER_ACTION_DISPATCH';
export const USER_ACTION_DISPATCH_FAIL = 'USER_ACTION_DISPATCH_FAIL';
export const LOG_USERS_ACTION_LIST_FAIL = 'LOG_USERS_ACTION_LIST_FAIL';