import { 
    FETCH_ALL_DOSSIER, DOSSIER_ERROR, FETCH_ONE_DOSSIER, SET_LOADING_DOSSIER,FETCH_INDEX_DOSSIER ,
    FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST, FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS, FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL,
    FILES_TREE_REQUEST, FILES_TREE_SUCCESS, FILES_TREE_FAIL, FILES_TREE_RESET,
    SET_CURRENT_PATHNAME, PATHNAME_REQUEST, PATHNAME_FAIL,
    FILE_INFO_REQUEST, FILE_INFO_SUCCESS, FILE_INFO_FAIL, SET_CURRENT_FILEINFOS, FILE_INFO_RESET,
    STATS_REQUEST, STATS_SUCCESS, STATS_FAIL
} from '../actions/types';

const initialState = {
    allDOSSIER : null,
    detailsDOSSIER : null,
    loading : false,
    error : '',
    indexDOSSIER:null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_DOSSIER:
            return {
                ...state,
                allDOSSIER: action.payload,
                loading: false
            };
        case FETCH_ONE_DOSSIER:
            return {
                ...state,
                detailsDOSSIER: action.payload,
                loading: false
            };
        case FETCH_INDEX_DOSSIER:
            return {
                ...state,
                indexDOSSIER: action.payload,
                loading: false
            };
        case SET_LOADING_DOSSIER:
            return {
                ...state,
                loading: true
            };
        case DOSSIER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state;
    }
}

export const getActiveParentFoldersReducer = (state = { activeFolders: null }, action) => {
    switch (action.type) {
        case FOLDERS_ACTIVE_AND_PARENT_LIST_REQUEST:
            return { loading: true }
        case FOLDERS_ACTIVE_AND_PARENT_LIST_SUCCESS:
            return { loading: false, activeFolders: action.payload }
        case FOLDERS_ACTIVE_AND_PARENT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const filesTreeReducer = (state = { filesTree: null, currentPathname: null,currentPathnameString:null }, action) => {
    switch (action.type) {
        case FILES_TREE_REQUEST:
            return { ...state, loading: true,error:'' }
        case FILES_TREE_SUCCESS:
            return { ...state, loading: false, filesTree: action.payload,error:'' }
        case FILES_TREE_FAIL:
            return { ...state, loading: false, error: action.payload }
        case FILES_TREE_RESET:
            return { filesTree: null, currentPathname: null,error:'' }
        case PATHNAME_REQUEST:
            return { ...state, loadingPathname: true }
        case SET_CURRENT_PATHNAME:
            return {
                ...state,
                currentPathname: action.payload.id,
                currentPathnameString: action.payload.pathname,
                errorPathname: ''
            };
        case PATHNAME_FAIL:
            return { ...state, loadingPathname: false,currentPathnameString:'', currentPathname: action.payload.id, errorPathname: action.payload.error}
        default:
            return state
    }
}

export const fileInfosReducer = (state = { fileInfos: null, currentFileInfos : null }, action) => {
    switch (action.type) {
        case FILE_INFO_REQUEST:
            return { ...state, loading: true, error: ''}
        case FILE_INFO_SUCCESS:
            return { ...state, loading: false, fileInfos: action.payload, error:''}
        case FILE_INFO_FAIL:
            return { ...state, loading: false, error: action.payload, success : null }
        case SET_CURRENT_FILEINFOS:
            return {
                ...state,
                currentFileInfos: action.payload,
            };
        case FILE_INFO_RESET:
            return { fileInfos: null, currentFileInfos : null, error:''  }
        default:
            return state
    }
}

export const fileStatistiquesReducer = (state = { stats : null }, action) => {
    switch (action.type) {
        case STATS_REQUEST:
            return { loading: true, error: ''}
        case STATS_SUCCESS:
            return { loading: false, stats: action.payload, error:''}
        case STATS_FAIL:
            return { loading: false, error: action.payload, stats : null }
        default:
            return state
    }
}