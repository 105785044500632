import React, { Component } from 'react'
import { withRouter } from "react-router";
import { getFileInfos, resetFileInfos, getStats } from '../store/actions/listDocumentAction';
import { saveLogActionUser } from '../store/actions/historiquesUserAction';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import Message from '../components/Message'
import {dateFormatTexte, getIcon} from '../utils/function';
import { Link } from 'react-router-dom'
import { fetchLastDepotList } from '../store/actions/depotActions';

class RightSidebar extends Component {
    componentDidMount() {
        const { currentFileInfos, getFileInfos, getStats, fetchLastDepotList } = this.props;
        currentFileInfos && getFileInfos(currentFileInfos);
        getStats();
        fetchLastDepotList(10, 'date_update')
    }
    componentDidUpdate(prevProps, prevState) {
        const { currentFileInfos, getFileInfos, location, resetFileInfos } = this.props;
        if(location && location.pathname && location.pathname.includes('/home')){
            if (prevProps.currentFileInfos && currentFileInfos && prevProps.currentFileInfos !== currentFileInfos) {//current pathname changed
                getFileInfos(currentFileInfos);
            }
        }else{
            resetFileInfos();
        }
    }
    getSize = (sizeOctet) => {
        let rslt = '-'
        if (sizeOctet ===0) {
            rslt = '-'
        }else if((sizeOctet/1000) < 1000) {
            rslt= `${(sizeOctet/1024).toFixed(2)} Ko`
        }
        if ((sizeOctet/1000) > 1000) {
            rslt= `${(sizeOctet/1048576).toFixed(2)} Mo`
        }
        if ((sizeOctet/1000000) > 1000) {
            rslt= `${(sizeOctet/1073741824).toFixed(2)} Go`
        } 
        return rslt;
    }
    render() {
        const { fileInfos, loading, error, listDepots, currentDepot, userInfo, statsReducer, lastDepots } = this.props;
        return (
            <div className="sidebar-group d-print-none">
                {/* <!-- Sidebar - Storage --> */}
                <div className="sidebar primary-sidebar show" id="storage">
                    <div className="sidebar-header">
                        <h4>
                            {fileInfos && fileInfos.type === 2 && 'Informations fichier'}
                            {fileInfos && (fileInfos.type === 1 || fileInfos.type === 3)  && 'Informations dossier'}
                        </h4>
                    </div>
                    <div className="sidebar-content">
                        <div id="justgage_five" className="mb-3"></div>
                        <div>
                            {error && <Message variant='danger'>{error || "Une erreur est survenue"}</Message> }
                            {loading && <Loader/> }
                            {fileInfos && fileInfos.success ?
                                <div className="mb-4">
                                    <div className="list-group-flush mb-3">
                                        <div className="list-group-item px-0 text-center align-items-center">
                                            <div className="mr-3">
                                                <figure className="avatar">
                                                    <span className="avatar-title text-primary rounded">
                                                        <i 
                                                            className={getIcon(fileInfos.type, fileInfos.extension).icon}
                                                            style={{
                                                                color:getIcon(fileInfos.type, fileInfos.extension).color,
                                                                fontSize:'40px'
                                                            }}
                                                        >
                                                        </i>
                                                    </span>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1">
                                                <small className="mb-0">
                                                    {fileInfos.type === 2 && 'Nom du fichier'}
                                                    {(fileInfos.type === 1 || fileInfos.type === 3)  && 'Nom du dossier'}
                                                </small>
                                                <h5 className="mb-0">{fileInfos.filename}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {fileInfos.type === 2 && <p className="mb-0"><label><strong>Taille du fichier :</strong></label> {fileInfos.size}</p>}
                                    {fileInfos.type === 2 && fileInfos.extension && <p className="mb-0"><label><strong>Extension :</strong></label> {fileInfos.extension}</p>}
                                    {fileInfos.date_ajout && fileInfos.date_ajout.length > 0 && 
                                        <p className="mb-0"><label><strong>Date d'ajout :</strong></label> {dateFormatTexte(fileInfos.date_ajout)}</p>
                                    }
                                    {fileInfos.date_modif && fileInfos.date_modif.length > 0 && 
                                        <p className="mb-0"><label><strong>Modif. le :</strong></label> {dateFormatTexte(fileInfos.date_modif)}</p>
                                    }
                                    
                                    <p className="mb-0"><label><strong>Créé par :</strong></label> {fileInfos.userInfo_created}</p>
                                </div>
                                :
                                <div>
                                    {userInfo && userInfo.ref_role ==='admin' &&  !statsReducer.loading && !statsReducer.error && statsReducer.stats &&
                                        <div>
                                            <h4>Statistiques</h4>
                                            <div className="list-group list-group-flush mb-3">
                                            <div className="list-group-item px-0 d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <figure className="avatar">
                                                            <span className="storage-overview bg-primary-bright text-primary rounded">
                                                                <i className="ti-file"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">Depôts</p>
                                                    </div>
                                                    <div>
                                                        <h5 className="text-primary">{statsReducer.stats.nbrDepots}</h5>
                                                    </div>
                                                </div>
                                                <div className="list-group-item px-0 d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <figure className="avatar">
                                                            <span className="storage-overview bg-primary-bright text-primary rounded">
                                                                <i className="ti-image"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">Images</p>
                                                    </div>
                                                    <div>
                                                        <h5 className="text-primary">
                                                            {this.getSize(statsReducer.stats.imageStats.size)}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="list-group-item px-0 d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <figure className="avatar">
                                                            <span className="storage-overview bg-primary-bright text-primary rounded">
                                                                <i className="ti-control-play"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">Vidéos</p>
                                                    </div>
                                                    <div>
                                                        <h5 className="text-primary">{this.getSize(statsReducer.stats.videoStats.size)}</h5>
                                                    </div>
                                                </div>
                                                <div className="list-group-item px-0 d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <figure className="avatar">
                                                            <span className="storage-overview bg-primary-bright text-primary rounded">
                                                                <i className="ti-files"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">Documents</p>
                                                    </div>
                                                    <div>
                                                        <h5 className="text-primary">{this.getSize(statsReducer.stats.documentStats.size)}</h5>
                                                    </div>
                                                </div>
                                                {/* <div className="list-group-item px-0 d-flex align-items-center">
                                                    <div className="mr-3">
                                                        <figure className="avatar">
                                                            <span className="storage-overview bg-primary-bright text-primary rounded">
                                                                <i className="ti-file"></i>
                                                            </span>
                                                        </figure>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p className="mb-0">Autres documents</p>
                                                    </div>
                                                    <div>
                                                        <h5 className="text-primary">{this.getSize(statsReducer.stats.othersStats.size)}</h5>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    }
                                    {listDepots && listDepots.length > 0 ?
                                        (
                                            // listDepots && listDepots.length > 10 ?
                                            //     <p>Liste de{listDepots.slice(0, 10).length > 1 ? 's' : '' } {listDepots.slice(0, 10).length} dernier{listDepots.slice(0, 10).length > 1 ? 's' : '' } dépôt{listDepots.slice(0, 10).length > 1 ? 's' : '' } :</p>    
                                            //     :
                                            //     listDepots && listDepots.length === 1 ?
                                            //         <p>Votre dépôt :</p>
                                            //         :
                                            //         <p>Liste de vos dépôts : </p>
                                            <p>Les derniers dépôts</p>

                                        )
                                        :
                                        <p className="alert alert-info">Vous n'avez aucun dépôt.</p>
                                    }
                                    <div id="rightside-depot">
                                        <ul className="list-group list-group-flush">
                                            {lastDepots && lastDepots.length > 0 && lastDepots.slice(0, 10).map(folder => 
                                                <li key={folder.id_dossier} className="list-group-item d-flex" style={{backgroundColor:`${currentDepot?.slug === folder.slug ? '#ebebeb' : '#fff'}`}}>
                                                    <Link to={`/home?depot=${folder.id_dossier}`} onClick={() => {document.getElementById('mes-depots').classList.remove("show");this.props.saveLogActionUser(folder.id_dossier,'1')}} className="d-flex">
                                                        <div className=" mr-2">
                                                            <i className="fa fa-folder" style={{color:"#ffc542"}}></i>
                                                        </div>
                                                        <div>
                                                            <div className="mb-0">
                                                                {currentDepot?.slug === folder.slug ?
                                                                    <div>
                                                                        <span className="text-primary text-truncate">{folder.nom}</span>
                                                                        <i className="fa fa-check font-size-11 position-absolute right-0 top-0 mr-3 mt-3"></i>
                                                                    </div>:
                                                                        <span className="text-primary text-truncate d-block">{folder.nom}</span>
                                                                }
                                                                <small className="ml-1 d-block">
                                                                    {folder.description ?
                                                                        <div>{folder.description}</div>
                                                                        :
                                                                        <div style={{margin: '-6px'}}>Crée le: {dateFormatTexte(folder.date_ajout)}</div>
                                                                    }
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- ./ Sidebar - Storage --> */}

                {/* <!-- Sidebar - File info --> */}
                {/* <div className="sidebar" id="view-detail">
                    <div className="sidebar-header">
                        <h4>View Detail</h4>
                        <a href="#" className="btn btn-light btn-floating sidebar-close-btn">
                            <i className="ti-angle-right"></i>
                        </a>
                    </div>
                    <div className="sidebar-content">
                        <figure className="avatar mb-4">
                            <span className="avatar-title bg-info-bright text-info rounded-pill">
                                <i className="ti-file"></i>
                            </span>
                        </figure>
                        <div className="row mb-3">
                            <div className="col-md-5">File name:</div>
                            <div className="col-md-7"><a href="#" className="link-1">Meeting-notes.doc</a></div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5">File type:</div>
                            <div className="col-md-7">Word File</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5">File size:</div>
                            <div className="col-md-7">22 KB</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5">Created:</div>
                            <div className="col-md-7">Monday, July 02, 2020 9:34am</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5">Modified:</div>
                            <div className="col-md-7 text-success">Monday, July 02, 2020 9:34am</div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-5">Accessed:</div>
                            <div className="col-md-7">Monday, July 03, 2020 10:22am</div>
                        </div>
                    </div>
                    <div className="sidebar-footer">
                        <button className="btn btn-lg btn-block btn-primary">
                            <i className="ti-share mr-3"></i> Share
                        </button>
                    </div>
                </div>
                 */}
                {/* <!-- ./ Sidebar - file info --> */}

                {/* <!-- Sidebar - Settings --> */}
                {/* <div className="sidebar" id="settings">
                    <div className="sidebar-header">
                        <h4>Settings</h4>
                        <a href="#" className="btn btn-light btn-floating sidebar-close-btn">
                            <i className="ti-angle-right"></i>
                        </a>
                    </div>
                    <div className="sidebar-content">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch1">Allow notifications.</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                    <label className="custom-control-label" htmlFor="customSwitch2">Hide user requests</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch3" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch3">Speed up demands</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch4" defaultChecked />
                                    <label className="custom-control-label" htmlFor="customSwitch4">Hide menus</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                    <label className="custom-control-label" htmlFor="customSwitch5">Remember next visits</label>
                                </div>
                            </li>
                            <li className="list-group-item pl-0 pr-0">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch6" />
                                    <label className="custom-control-label" htmlFor="customSwitch6">Enable report
                                        generation.</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                 */}
                {/* <!-- ./ Sidebar - Settings --> */}
            </div>
        )    
    }
}

const mapStateToProps = ({ fileInfos, lastDepotsList, depotsList, auth, statsReducer }) => ({
    currentFileInfos: fileInfos.currentFileInfos,
    fileInfos : fileInfos.fileInfos,
    loading: fileInfos.loading,
    error: fileInfos.error,
    listDepots: depotsList.depots,
    lastDepots: lastDepotsList.depots,
    currentDepot: depotsList.currentDepot,
    userInfo : auth.userInfo,
    statsReducer
})

const mapDispatchToProps = {
    getFileInfos,
    resetFileInfos,
    getStats,
    fetchLastDepotList,
    saveLogActionUser
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RightSidebar));
