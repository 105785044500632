import { GET_LOG_USER,LOG_USER_LIST_FAIL } from '../actions/types'


const initialState = {
    logsUsers: [],
    error: null,
};

export const userLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOG_USER:
            return {
                logsUsers: action.payload,
              }; 
        case LOG_USER_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                };          
        default:
            return state
    }
}

