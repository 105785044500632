import React, {useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-notifications/lib/notifications.css';
import DataTable from '../../components/DataTable'
import { getAllRoles, deleteRole } from '../../store/actions/roleActions';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { NotificationManager } from 'react-notifications';
import Layout from '../Layout';
import { ROLE_DETAILS_RESET } from '../../store/RoleTypes'

const RolesList = () => {
    const colsArray = [
        {
            label: 'ID',
            field: 'id_role',
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'ID',
            },
        },
        {
            label: 'Réference',
            field: 'ref_role',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Réference',
            },
        },
        {
            label: 'Nom rôle',
            field: 'nom_role',
            width: 150,
            sort: 'asc',
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Nom & prénom',
            },
        },
        {
            label: 'Actif',
            field: 'actif',
            width: 150,
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Actif',
            },
        },
        {
            label: 'Actions',
            field: 'actions',
            width: 150,
            attributes: {
                'aria-controls': 'DataTable',
                'aria-label': 'Actions',
            },
        }
    ]

    const dispatch = useDispatch();
    const {roles} = useSelector((state) => state.roles);

    const roleDelete = useSelector((state) => state.roleDelete)
    const { success: successDelete, error: errorDelete } = roleDelete
    

    useEffect(() => {
        dispatch({ type: ROLE_DETAILS_RESET })
        dispatch(getAllRoles())
        if (successDelete) {
            NotificationManager.success('Vos modifications ont été effectués avec succés', 'Succés', 5000);
        }else if (errorDelete) {
            NotificationManager.error(`Une erreur est survenue : ${errorDelete}`, 'Erreur', 5000);
        }
    }, [dispatch, successDelete, errorDelete])
    const getRowsArray = () => {
        let rowsArray = [];
        roles.map((role) => {
            const row = {
                id_role: role.id_role,
                ref_role: role.ref_role,
                nom_role: role.nom_role,
                actif: role.actif === 1 ? 
                            <div className="badge bg-success-bright text-success">Actif</div> :
                            <div className="badge bg-danger-bright text-danger">Non Actif</div>,
                actions: getLayoutActions(role) 
            }
            rowsArray.push(row)
            return null;
        });
        return rowsArray;
    }
    const getLayoutActions = (role) => (
        role.ref_role !== 'admin' ?
        <div className="dropdown">
            <a href="#" className="btn btn-floating" data-toggle="dropdown">
                <i className="ti-more-alt"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
                <Link to={`/admin/role/${role.id_role}/edit`} className="dropdown-item"><i className='fas fa-edit'></i> Modifier</Link>
                <Link
                    to="#"
                    className='dropdown-item'
                    onClick={() => deleteRoleHandler(role.id_role)} 
                >
                    <i className='fas fa-trash'></i> Supprimer
                </Link>
            </div>
        </div> : 
        <div></div>
    )
    const deleteRoleHandler = (id_role) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Etes-vous sûr de vouloir supprimer ce rôle?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => dispatch(deleteRole(id_role))
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }
    return (
        <>
            <Layout>
                <div className="page-header d-flex justify-content-between">
                    <div className="content-title" style={{width: "100%"}}>
                        <h4>Liste des rôles</h4>
                    </div>
                    <div className="btn" style={{margin: '30px 0', width:'20%'}}>
                        <Link to="/admin/role/create"> 
                            <i className="fas fa-plus-circle"></i> 
                            <span className="px-2 d-none d-md-block">Ajouter un rôle</span>
                        </Link>
                    </div>
                </div>
                {
                    roles && roles.length> 0 ?
                        <DataTable
                            rows = {getRowsArray()}
                            columns = {colsArray}
                            pagesRange = {[20, 30, 50, 100]}
                        /> :
                        <DataTable
                            columns = {colsArray}
                            pagesRange = {[20, 30, 50, 100]}
                        />
                }              
            </Layout>
        </>
    )
}

export default RolesList;