export const ROLE_DETAILS_REQUEST = 'ROLE_DETAILS_REQUEST';
export const ROLE_DETAILS_SUCCESS = 'ROLE_DETAILS_SUCCESS';
export const ROLE_DETAILS_FAIL = 'ROLE_DETAILS_FAIL';
export const ROLE_DETAILS_RESET = 'ROLE_DETAILS_RESET';

export const ROLE_CREATION_REQUEST = 'ROLE_CREATION_REQUEST';
export const ROLE_CREATION_SUCCESS = 'ROLE_CREATION_SUCCESS';
export const ROLE_CREATION_FAIL = 'ROLE_CREATION_FAIL';
export const ROLE_CREATION_RESET = 'ROLE_CREATION_RESET';

export const ROLE_UPDATE_REQUEST = 'ROLE_UPDATE_REQUEST';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';
export const ROLE_UPDATE_FAIL = 'ROLE_UPDATE_FAIL';
export const ROLE_UPDATE_RESET = 'ROLE_UPDATE_RESET';

export const ROLE_DELETE_REQUEST = 'ROLE_DELETE_REQUEST';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const ROLE_DELETE_FAIL = 'ROLE_DELETE_FAIL';