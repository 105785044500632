import {    
    USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LIST_FAIL, 
    USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_DETAILS_FAIL,USER_DETAILS_RESET,
    USER_CREATION_REQUEST, USER_CREATION_SUCCESS, USER_CREATION_FAIL,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL,
    USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL
} from './types';
import API from '../../utils/API';

export const listUsers = () => async(dispatch) => {
    try {

        dispatch({ type: USER_LIST_REQUEST })

        const { data } = await API.get(`/users`)

        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data.results,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: USER_LIST_FAIL,
            payload: message,
        })
    }
}
export const getUserDetails = (id) => async(dispatch) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST,
        })

        const { data } = await API.get(`/users/${id}`)

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data.results && data.results.length > 0 ? data.results[0] : {},
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: message,
        })
    }
}

export const createUser = (userData) => async(dispatch) => {
    try {
        dispatch({
            type: USER_CREATION_REQUEST,
        })

 
    const { data } = await API.post(`/users`, userData)
 

        dispatch({
            type: USER_CREATION_SUCCESS,
            payload: data.results,
        })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: USER_CREATION_FAIL,
            payload: message,
        })
    }
}

 
export const updateUser = (id, userData) => async(dispatch) => {
 
    try {
        dispatch({
            type: USER_UPDATE_REQUEST,
        })

 
        const { data } = await API.put(`/users/${id}`, userData)
 

        dispatch({
            type: USER_UPDATE_SUCCESS,
            payload: data.results,
        })
        dispatch({ type: USER_DETAILS_RESET })
    } catch (error) {
        const message =
            error.response && error.response.data.error ?
            error.response.data.error :
            error.message
        dispatch({
            type: USER_UPDATE_FAIL,
            payload: message,
        })
    }
}

export const deleteUser = (id) => async (dispatch) => {
    try {
        dispatch({
            type: USER_DELETE_REQUEST
        })

    await API.delete(`/users/${id}`)

    dispatch({
        type: USER_DELETE_SUCCESS
    })
    } catch (error) {
        const message =
            error.response && error.response.data.error
                ? error.response.data.error
                : error.message
        dispatch({
            type: USER_DELETE_FAIL,
            payload: message,
        })
    }
}

