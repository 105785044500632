import { MDBDataTableV5 } from 'mdbreact';

const DataTable = ({columns= [], rows = [], pagesRange, widthPagination = true}) => {
    return (
        <MDBDataTableV5 
            hover 
            noRecordsFoundLabel="Aucun enregistrement trouvé"
            entriesLabel="Nombre d'élément"
            infoLabel= { ['Voir', 'à', 'de', 'éléments']}
            paginationLabel= {['Précédant', 'Suivant']}
            searchLabel= {'Rechercher'}
            responsiveMd
            entriesOptions={pagesRange} 
            entries={20} 
            pagesAmount={4} 
            data={{columns, rows}} 
            searchTop 
            searchBottom={false} 
            paging={widthPagination}
            info={widthPagination}
        />
    )
}

export default DataTable
