import { useState, useEffect } from 'react'
import { Form, Button, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import FormContainer from '../../components/FormContainer'
import Layout from '../Layout';
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { getUserDetails, updateUser, createUser } from '../../store/actions/userActions'
import { updateUserPasswd, isAuthenticated } from '../../store/actions/authAction'
import {getUrlSiteByEnv} from '../../utils/function';
import API from '../../utils/API';
import { USER_UPDATE_RESET, USER_CREATION_RESET, ACCESS_BATCH_ADD_UPDATE_RESET, USER_UPDATE_PASSWORD_RESET } from '../../store/actions/types'
import {UPDATE_CURRENT_DEPOT} from '../../store/constants/depots'
import { getAllRoles } from '../../store/actions/roleActions'
import { fetchDepotList } from '../../store/actions/depotActions'
import { batchAddUpdateAccess, getUserAccess } from '../../store/actions/accessActions'
import { listActiveParentFolders } from '../../store/actions/listDocumentAction'

const UserDetailsScreen = ({match, history }) => {
    const userId = match.params.id;

    const [ref, setRef] = useState('')
    const [email, setEmail] = useState('')
    const [nomPrenom, setNomPrenom] = useState('')
    const [image, setImage] = useState('')
    const [isActif, setIsActif] = useState('')
    const [role, setRole] = useState('')
    const [dossier, setDossier] = useState('')
    const [password, setPassword] = useState('')

    const [uploading, setUploading] = useState(false)
    const [uploadingError, setUploadingError] = useState('')
    const [deleteImageError, setDeleteImageError] = useState('')

    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')

    const [accessArray, setAccessArray] = useState([])
    const [isSubmittableAccess, setIsSubmittableAccess] = useState(false)
    
    const dispatch = useDispatch()

    const userDetails = useSelector((state) => state.userDetails)
    const { loading, user } = userDetails

    const accessUpdates = useSelector((state) => state.accessUpdates)
    const { loading: loadingAccessUpdate, error: errorAccessUpdate, success: successAccessUpdate } = accessUpdates

    const accessUser = useSelector((state) => state.accessUser)
    const { loading: loadingUserAccess, error: errorUserAccess, access: userAccess } = accessUser

    const { roles, loading: loadingRoles} = useSelector((state) => state.roles)
    const { depots, loading: loadingDepots} = useSelector((state) => state.depotsList)
    const { activeFolders, loading: loadingFolders, error: errorFolders} = useSelector((state) => state.activeParentFolder)

    const authInfos = useSelector((state) => state.auth)
    const { userInfo, successUpdatePasswd, loading: loadingUpdatePasswd, error: errorUpdatePasswd} = authInfos
    
    const userUpdate = useSelector((state) => state.userUpdate)
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate} = userUpdate

    const userCreate = useSelector((state) => state.userCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate} = userCreate

    useEffect(() => {
        !roles && !loadingRoles && dispatch(getAllRoles())
        roles && roles.length > 0  && setRole(roles[0].id_role)
    }, [dispatch, roles, loadingRoles])

    useEffect(() => {
        !depots && !loadingDepots &&  dispatch(fetchDepotList())//!dossiers && !loadingDossiers &&  dispatch(getAllDossiers())
        depots && depots.length > 0 && setDossier(depots[0].id_dossier)//dossiers && dossiers.length > 0 && setDossier(dossiers[0].id_dossier)
    }, [dispatch, depots, loadingDepots])// [dossiers, loadingDossiers]

    useEffect(() => {
        !activeFolders && !loadingFolders && dispatch(listActiveParentFolders())
    }, [dispatch, activeFolders, loadingFolders])

    useEffect(() => {
        
        if (successUpdate || successCreate) {
            NotificationManager.success('Modification effectuées avec succés', 'Succés', 5000);
            //if modified user is the connected one, then research his infos
            user && userInfo && user.id_user === userInfo.id_user && dispatch(isAuthenticated())
            user && dispatch({ 
                type: UPDATE_CURRENT_DEPOT, 
                payload : {
                    id: user.id_dossier_depot, 
                    slug: null
                }  
            }) 
            successUpdate ? dispatch({ type: USER_UPDATE_RESET }) : dispatch({ type: USER_CREATION_RESET })
            
            history.push('/admin/users')
        } else {
            if (userId && !user ) {
                dispatch(getUserDetails(userId))
                dispatch(getUserAccess(userId))
            }else if(user && user.ref_user){
                setRef(user.ref_user)
                setEmail(user.email)
                setImage(user.image)
                setNomPrenom(user.nom_prenom)
                setIsActif(user.actif)
                setRole(user.id_role)
                setDossier(user.id_dossier_depot)
                if (successUpdatePasswd) {
                    dispatch({ type: USER_UPDATE_PASSWORD_RESET })
                    NotificationManager.success('Vos modifications ont été effectués avec succés', 'Succés', 5000);
                    history.push('/admin/users')
                }
                
            } else {
                history.push('/admin/user/create')
            }
        }
    }, [dispatch, history, userId, user, successUpdate, successCreate, successUpdatePasswd, userInfo])

    useEffect(() => {
        //initilize Access array
        userAccess && setAccessArray(userAccess) 
    }, [userAccess])
    useEffect(() => {
        if (!loadingAccessUpdate && successAccessUpdate) {
            NotificationManager.success('Modification effectuées avec succés', 'Succés', 5000);
            dispatch({ type: ACCESS_BATCH_ADD_UPDATE_RESET })
            history.push('/admin/users')
        }
        if (!loadingAccessUpdate && errorAccessUpdate) {
            NotificationManager.error('Une erreur est survenue', 'Erreur', 5000);
            dispatch({ type: ACCESS_BATCH_ADD_UPDATE_RESET })
        }
    }, [dispatch, history, loadingAccessUpdate, successAccessUpdate, errorAccessUpdate])

    const changeAccess = (isGranted, action,folderID) => {
        if(action === "read" && isGranted){
            document.getElementById("Exclure"+folderID).checked = false;
        }
        if(action === "read" && !isGranted){
            document.getElementById("Write"+folderID).checked = false;
        }

        if(action === "write" && isGranted){
            document.getElementById("Read"+folderID).checked = true;
            document.getElementById("Exclure"+folderID).checked = false;
        }
        if(action === "exclure" && isGranted){
            document.getElementById("Read"+folderID).checked = false;
            document.getElementById("Write"+folderID).checked = false;
        }
        //accessArray=[{ id_dossier:1, action:'read,write' }, {id_dossier:2, action:'read'}...]
        //check if folderID deja in state (accessArray) : if true, update that object else add it to the accessArray
        //when updating the accessArray, if isGranted add the action to the action string else remove it from the string
        if (folderExists(folderID)) {
            const tempArray = accessArray;
            //Find index of specific object using findIndex method.    
            const objIndex = tempArray.findIndex((obj => obj.id_dossier === folderID));
            //Update object's action property.
            // isGranted ?
            //     tempArray[objIndex].action += `,${action}` :
            //     tempArray[objIndex].action = tempArray[objIndex].action.replaceAll(`${action}`, "");
            // console.log(tempArray)
            let actions = [];
            if(document.getElementById("Read"+folderID).checked) actions.push('read');
            if(document.getElementById("Write"+folderID).checked) actions.push('write');
            if(document.getElementById("Exclure"+folderID).checked) actions.push('exclure');
            tempArray[objIndex].action = actions.join(',');
            setAccessArray(tempArray)
            setIsSubmittableAccess(true)
        } else {
            setAccessArray([...accessArray, {id_dossier:folderID, action}])
            setIsSubmittableAccess(true)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault()

        const submittedUser = {
            actif :isActif,
            id_role:Number(role),
            nom_prenom:nomPrenom,
            ref_user:ref,
            id_dossier_depot:Number(dossier),
            email:email,
        }
        if (user && user.id_user) {
            dispatch(updateUser(user.id_user, submittedUser))
        } else {
            submittedUser.password = password;
            dispatch(createUser(submittedUser))
        }
    }
    const submitAccessChangesHandler =(e) =>{
        e.preventDefault()
        isSubmittableAccess && dispatch(batchAddUpdateAccess(user.id_user, accessArray))
    }
    const changePasswdHandler = (e) => {
        e.preventDefault()
        if (newPassword.length < 8 ) {
            return NotificationManager.error('Mot de passe trop court', 'Erreur', 5000);
        }
        if(newPassword !== newPassword2 ) {
            return NotificationManager.error('Mot de passe non identique', 'Erreur', 5000);
        }
        dispatch(updateUserPasswd(true, user.id_user, null, newPassword))
    }

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)
    
        try {
            const response = await API.post(`/users/updateImage/${user.id_user}`, formData)
            const { data:{success, uploadedImage }} = response;
            if (success) {
                setImage(uploadedImage)
                setUploading(false)
                setUploadingError('')
            }
        } catch (err) {
            console.error(err.message)
            setUploadingError(err.response && err.response.data ? err.response.data.error : err.message)
            setUploading(false)
        }
    }

    const deleteImageHandler = (e) => {
        e.preventDefault()
        const deleteImage = async() => {
            try {
                const response = await API.get(`/users/deleteImage/${user.id_user}`)
                const { data:{success }} = response;
                if (success) {
                    setImage('')
                }
            } catch (err) {
                console.error(err.message)
                setDeleteImageError(err.response && err.response.data ? err.response.data.error : err.message)
            }
        }
        confirmAlert({
            title: 'Confirmation',
            message: 'êtes-vous sûr de vouloir supprimer cet avatar?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => deleteImage()
                },
                {
                    label: 'Non',
                    onClick: () => null
                }
            ]
        });
    }
    
    const folderExists = folderId => accessArray.some((elem) => elem.id_dossier === folderId );
    const isAccessGranted = (action, folderId) => userAccess && userAccess.some(item => item.id_dossier === Number(folderId) && item.action.includes(action));
    
    const getAdminIdOfRole = () => {
        let id= null;
        if (roles) {
            const idAdmin= roles.filter(role=> role.ref_role === 'admin')
            idAdmin && idAdmin.length>0 && (id= idAdmin[0].id_role)
        }
        return id;
    }
    return (
        <Layout>
            <div className="page-header d-flex justify-content-between">
                <div className="content-title" style={{width: "100%"}}>
                    <h4>{userId ? `Modifier l'utilisateur ${userId}` : 'Ajouter un utilisateur' } :</h4>
                </div>
            </div>
            <ul className="nav nav-tabs" style={{margin: "52px"}}  role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="infos-tab" data-toggle="tab" href="#infos" role="tab"
                    aria-controls="infos" aria-selected="true">
                        Informations
                    </a>
                </li>
                {user &&
                    <li className="nav-item">
                        <a className="nav-link" id="access-tab" data-toggle="tab" href="#access" role="tab"
                        aria-controls="access" aria-selected="false">
                            Accés
                        </a>
                    </li>
                }
                {user &&
                    <li className="nav-item">
                        <a  className="nav-link" id="passwd-tab" data-toggle="tab" href="#passwd" role="tab"
                            aria-controls="passwd" aria-selected="false">
                            Changer mot de passe
                        </a>
                    </li>
                }
                
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="infos" role="tabpanel" aria-labelledby="infos-tab">
                
                    {(loadingUpdate || loadingCreate) && <Loader />}
                    {(errorUpdate || errorCreate) && <Message variant='danger'>{errorUpdate || errorCreate}</Message>}
                    {loading ? 
                        <Loader /> : 
                            <FormContainer>
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId='actif'>
                                        <Form.Check
                                            type='checkbox'
                                            label='Actif'
                                            checked={isActif}
                                            onChange={(e) => setIsActif(e.target.checked)}
                                        ></Form.Check>
                                    </Form.Group>
                                    <Form.Group controlId='Réference'>
                                        <Form.Label>Réference</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Entrer une réference'
                                            value={ref}
                                            onChange={(e) => setRef(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='nomPrenom'>
                                        <Form.Label>Nom/prénom</Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Entrer Nom/prénom'
                                            value={nomPrenom}
                                            onChange={(e) => setNomPrenom(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Form.Group>
                                    
                                    <Form.Group controlId='email'>
                                        <Form.Label>Adresse Email</Form.Label>
                                        <Form.Control
                                            type='email'
                                            placeholder='Entrer email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="roles">
                                        <Form.Label>Roles</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            onChange={(e) => setRole(e.target.value)}
                                            value={role}
                                            required
                                        >
                                            {roles && roles.map(role => <option key={role.id_role} value={role.id_role}>{role.nom_role}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="dossiers">
                                        <Form.Label>Dossier dépot</Form.Label>
                                        <Form.Control 
                                            as="select"
                                            onChange={(e) => setDossier(e.target.value)}
                                            value={dossier}
                                            required
                                        >
                                            {depots && depots.map(dossier => <option key={dossier.id_dossier} value={dossier.id_dossier}>{dossier.nom}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                    {!userId &&
                                        <Form.Group controlId='passwd'>
                                            <Form.Label>Mot de passe</Form.Label>
                                            <Form.Control
                                                type='password'
                                                placeholder='Entrer un mot de passe'
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            ></Form.Control>
                                        </Form.Group>
                                    }
                                    {user &&
                                        <Form.Group controlId='image'>
                                            <Form.Label>Image</Form.Label>
                                            <Form.File
                                                id='image-file'
                                                label='Choisir une image'
                                                custom
                                                onChange={uploadFileHandler}
                                            ></Form.File>
                                            {uploading && <Loader />}
                                            {(uploadingError || deleteImageError) && <Message variant='danger'>{uploadingError || deleteImageError}</Message>}
                                        </Form.Group>
                                    }
                                    <div className="text-center mb-3">
                                        { image ?
                                            <figure className="avatar avatar-xl avatar-edit">
                                                <div className="clicked-avatar" style={{cursor:'pointer'}} onClick={deleteImageHandler}></div>
                                                <img 
                                                    className="rounded-circle img-fluid mb-3" 
                                                    src={`${getUrlSiteByEnv()}/${process.env.REACT_APP_USER_IMAGE_FOLDER}/${image}`} 
                                                    alt="user" 
                                                /> 
                                            </figure>:
                                            <figure className="avatar avatar-xl ">
                                                <span className="avatar-title rounded-circle">
                                                    <i className="far fa-user"></i> 
                                                </span>
                                            </figure>
                                        }
                                    </div>
                                    <div className="text-center">
                                        <Button type='submit' variant='primary'>
                                            {userId ? `Modifier` : 'Ajouter' }
                                        </Button>
                                        <Link to="/admin/users">
                                            <Button variant='info' className="m-2">
                                                Revenir à la liste
                                            </Button>
                                        </Link>
                                    </div>
                                </Form>
                                
                            </FormContainer>
                    }
                </div>
                <div className="tab-pane fade" id="access" role="tabpanel" aria-labelledby="access-tab">
                    {user &&
                        <>
                            <p>
                                Sélectionnez les dépôts auxquels les utilisateurs ayant ce rôle auront accès. Utilisez la valeur 'Exclure' 
                                pour soit écraser un droit donné par un autre rôle, ou définitivement interdire l'accès 
                                à ce dépôt pour les rôles hérités. 
                            </p>
                            <div className="user-access-table">
                                {(errorFolders || errorUserAccess) && <Message variant='danger'>{errorFolders || errorUserAccess}</Message> }
                                {(loadingFolders || loadingUserAccess) && <Loader />}
                                    <Table responsive="sm" striped>
                                        <thead>
                                            <tr>
                                                <th style={{width: '70%'}}></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {depots && userAccess ?
                                                depots.map(folder =>
                                                    <tr key={folder.id_dossier}>
                                                        <td style={{cursor:'pointer'}}>
                                                            <span className="px-3"><i className="fas fa-folder"></i></span>
                                                            {folder.nom}
                                                        </td>
                                                        <td>
                                                            <div className="user-access">
                                                                <Form.Check
                                                                    id={`Read${folder.id_dossier}`}
                                                                    type='checkbox'
                                                                    label='Lire'
                                                                    defaultChecked={isAccessGranted('read', folder.id_dossier) || user.id_role === getAdminIdOfRole()}
                                                                    disabled={user.id_role === getAdminIdOfRole()}
                                                                    onClick={(e) => changeAccess(e.target.checked,'read', folder.id_dossier)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                    id={`Write${folder.id_dossier}`}
                                                                    type='checkbox'
                                                                    label='Ecrire'
                                                                    disabled={user.id_role === getAdminIdOfRole()}
                                                                    defaultChecked={isAccessGranted('write', folder.id_dossier) || user.id_role === getAdminIdOfRole()}
                                                                    onClick={(e) => changeAccess(e.target.checked,'write', folder.id_dossier)}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                    id={`Exclure${folder.id_dossier}`}
                                                                    type='checkbox'
                                                                    label='Exclure'
                                                                    disabled={user.id_role === getAdminIdOfRole()}
                                                                    defaultChecked={isAccessGranted('exclure', folder.id_dossier) || user.id_role === getAdminIdOfRole()}
                                                                    onClick={(e) => changeAccess(e.target.checked,'exclure', folder.id_dossier)}
                                                                ></Form.Check>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                        ) :
                                                    <tr><p>Aucun dossier!</p></tr>
                                            }
                                            
                                        </tbody>
                                    </Table>
                            </div>
                            <div className="text-center m-4">
                                <Button variant='primary' onClick={submitAccessChangesHandler} disabled={!isSubmittableAccess}>
                                    Modifier
                                </Button>
                            </div>
                        </>
                    }
                </div>
                <div className="tab-pane fade" id="passwd" role="tabpanel" aria-labelledby="passwd-tab">
                    {loadingUpdatePasswd && <Loader />}
                    {errorUpdatePasswd && <Message variant='danger'>{errorUpdatePasswd}</Message>}
                    {user &&
                        <FormContainer>
                            <Form onSubmit={changePasswdHandler}>
                                <Form.Group controlId='passwd'>
                                    <Form.Label>Nouveau mot de passe</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Mot de passe'
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                                
                                <Form.Group controlId='passwd'>
                                    <Form.Label>Retaper le nouveau mot de passe</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder='Mot de passe'
                                        onChange={(e) => setNewPassword2(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Button type='submit' variant='primary'>
                                    Modifier
                                </Button>
                            </Form>
                        </FormContainer>
                    }
                </div>
                
            </div>
        </Layout>
    )
}

export default UserDetailsScreen
