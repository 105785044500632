import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = {};

const middlewares = [thunk];
const devTools =
    process.env.NODE_ENV === "production"
        ? applyMiddleware(...middlewares)
        : composeWithDevTools(
            applyMiddleware(...middlewares),
            // other store enhancers if any
        );
        
const store = createStore(
    rootReducer,
    initialState,
    devTools
);

export default store; 