import Header from '../components/Header';
import SidebarNavigation from '../components/SidebarNavigation';
import RightSidebar from '../components/RightSidebar';
import Footer from '../components/Footer';
import { useEffect } from 'react'

const Layout = ({children}) => {

    const handleClick = (e) => {
        e.preventDefault();

        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const handleScroll = () => {
        var rootElement = document.documentElement
        var scrollToTopBtn= document.querySelector(".backtotop")
        if (scrollToTopBtn) {
            rootElement.scrollTop >rootElement.clientHeight ? 
                scrollToTopBtn.classList.add("showBtnBacktotop") ://show button
                scrollToTopBtn.classList.remove("showBtnBacktotop")//hide button
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)
    }, [])

    return (
        <div className="layout-wrapper">

            <Header />

            <div className="content-wrapper">

                <SidebarNavigation />

                <div className="content-body">
                    <div className="content">
                    
                        {children}
                    </div>
                    <Footer />
                </div>

                <div onClick={ (e) => handleClick(e)} className="backtotop" style={{cursor:'pointer'}}><i className="ti-angle-up"></i></div>

                <RightSidebar />

            </div>
            
        </div>
    )
}

export default Layout
